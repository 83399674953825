<template lang="pug">
.navbar
  .navbar--top
    .navbar--inner
      .navbar--hamburger(
        @click="toggleNavbar()",
        :class="{ 'hamburger-open': navbarOpen }"
      )
        .hamburger-item.short.top
        .hamburger-item.long
        .hamburger-item.short.bottom
        .hamburger-text menu
      nuxt-link.navbar--logo(to="/")
      .navbar--search(@click="toggleSearch")
        span {{ searchValueStore || searchPlaceholder }}
        i.far.fa-times.navbar--empty-icon(v-if="searchValueStore")
        i.far.fa-search.navbar--search-icon(v-else)
      search(
        v-model="searchValue",
        :open="searchOpen",
        :placeholder="searchPlaceholder",
        @input="search"
        @close="closeButKeepText"
        @empty="closeSearch"
      )
      nav.navbar--icons(:class="{ 'has-pwa-install-icon': showPWAInstallButton }")
        .navbar--item.mobile-search-item(@click="toggleSearch")
          i.navbar--icon.fal.fa-search
        .navbar--item.user-menu-item(@click="toggleUserDropdown")
          i.navbar--icon.user.fal.fa-user
          i.navbar--icon.user-mobile.fal.fa-user
          span.navbar--username(:class="{ visible: $auth.loggedIn }") {{ $auth.user ? $auth.user.firstname : '' }}
          i.navbar--icon.arrow.fal.fa-angle-down(
            :class="{ visible: $auth.loggedIn, 'fa-angle-down': !userDropdownOpen, 'fa-angle-up': userDropdownOpen }"
          )
          navbar-dropdown(
            v-model="userDropdownOpen",
            :items="userDropdownItems"
          )
        nuxt-link.navbar--item(
          to="/winkelmandje",
          @click.native="toggleNavbar(false)"
        )
          i.navbar--icon.fal.fa-shopping-basket
          span.navbar--cartamount(:class="{ empty: !cartAmount }") {{ cartAmount }}
        .navbar--item.pwa-install-item(v-if="showPWAInstallButton" @click="openPWAInstallPopup")
          i.navbar--icon.fas.fa-cloud-download.pwa-install
  .navbar--bottom(
    :class="{ visible: navbarOpen, elevated: subnavOpen }"
  )
    .navbar--mainmenu(@click="toggleNavbar()")
      nuxt-link.navbar--link(
        v-for="item in menuItems",
        :key="item.link",
        :to="item.link"
      ) {{ item.label }}
      button.button.navbar--link.more(@click="subnavOpen = !subnavOpen")
        span Meer
        i.far.icon-right.navbar--link-icon(
          :class="{ 'fa-angle-down': !subnavOpen, 'fa-angle-up': subnavOpen }"
        )
        navbar-dropdown(v-model="subnavOpen", :items="submenuItems")
    .navbar--mobile(@click="toggleNavbar()")
      nuxt-link.button.gold.fullwidth.high.navbar--button(to="/producten") Begin met winkelen
      .navbar--submenu
        nuxt-link.navbar--link(
          v-for="item in submenuItems",
          :key="item.link",
          :to="item.link"
        ) {{ item.label }}
        button.button.light.fullwidth.high.outlined.contact(
          @click="showIntercom"
        ) Vraag? Chat met ons
</template>

<script>
import { intercomMixin } from '@/mixins/intercomMixin';
import { pwaMixin } from '@/mixins/pwaMixin';
import { mapGetters } from 'vuex';

export default {
  components: {
    'navbar-dropdown': () => import('@/components/navbar/navbarDropdown'),
    search: () => import('@/components/search/search'),
  },
  mixins: [intercomMixin, pwaMixin],
  data() {
    return {
      navbarOpen: false,
      subnavOpen: false,
      searchOpen: false,
      searchValue: '',
      searchPlaceholder: 'Welke boodschappen zoek je?',
      userDropdownOpen: false,
      deferredPrompt: null,
      showPWAInstallButton: false,
    };
  },
  computed: {
    ...mapGetters({
      searchValueStore: 'shared/searchValue',
      mainNavigationGlobal: 'api/mainNavigationGlobal',
      iosPwaInstallPopup: 'shared/iosPwaInstallPopup',
    }),
    cart() {
      return this.$auth.user?.cart?.data || {};
    },
    menuItems() {
      return [
        ...this.mainNavigationGlobal.map((item) => ({
          label: item.name,
          link: `/${item.path}`,
        })),
        { label: 'Boodschappen doen', link: '/producten' },
        { label: 'Aanbiedingen', link: '/aanbiedingen' },
        { label: 'Recepten', link: '/recepten' },
        { label: 'Vers verhalen', link: '/verhalen' },
      ];
    },
    submenuItems() {
      return [
        { label: 'Ontmoet onze boeren', link: '/boeren' },
        { label: 'Over Hofweb', link: '/dit-zijn-wij' },
        { label: 'Biologisch op zijn best', link: '/producten' },
        { label: 'Werken bij', link: '/werkenbij' },
        ...(!this.$auth.loggedIn
          ? [{ label: 'Aanmelden', link: '/aanmelden' }]
          : []),
        { label: 'Contact en service', link: '/contact-service/contact' },
      ];
    },
    userDropdownItems() {
      const login = {
        label: 'Inloggen',
        link: '/aanmelden',
        icon: 'fa-sign-in',
      };
      const account = {
        label: 'Mijn hofweb',
        link: '/account',
        icon: 'fa-user-circle',
      };
      const logout = {
        label: 'Uitloggen',
        link: '/logout',
        icon: 'fa-power-off',
      };

      return [
        ...(this.$auth.loggedIn ? [account] : [login]),
        {
          label: 'Mijn bestellingen',
          link: '/account/mijn-bestellingen',
          icon: 'fa-truck',
        },
        {
          label: 'Bestel uit vorige orders',
          link: '/account/bestelhistorie',
          icon: 'fa-history',
        },
        {
          label: 'Mijn favorieten',
          link: '/account/favorieten',
          icon: 'fa-heart',
        },
        {
          label: 'Mijn favoriete Hofweb-recepten',
          link: '/account/recepten',
          icon: 'fa-utensils-alt',
        },
        {
          label: 'Mijn vaste producten',
          link: '/account/vaste-producten',
          icon: 'fa-thumbtack',
        },
        {
          label: 'Mijn statiegeld kratten',
          link: '/account/mijn-kratten',
          icon: 'fa-apple-crate',
        },
        ...(this.$auth.loggedIn ? [logout] : []),
      ];
    },
    cartAmount() {
      const subtotal = Number(this.cart.subtotal);
      const discount = Number(this.cart.discount);

      // Adding discount to subtotal because it is a negative number
      // Simple example: 50 + -10 = 40
      if (subtotal + discount) {
        return (subtotal + discount).toFixed(2);
      }
      return '';
    },
  },
  mounted() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();

      this.deferredPrompt = e;
      this.showPWAInstallButton = true;
    });

    if (this.isIOS) {
      this.showPWAInstallButton = true;
    }
  },
  methods: {
    toggleNavbar(value) {
      this.navbarOpen = value !== undefined ? value : !this.navbarOpen;
    },
    closeButKeepText() {
      this.searchOpen = false;

      this.navbarOpen = false;
    },
    toggleSearch() {
      this.searchOpen = !this.searchOpen;

      this.navbarOpen = false;
    },
    closeSearch() {
      this.searchOpen = false;
      this.searchValue = '';
      this.$store.commit('shared/setSearchValue', '');
      this.navbarOpen = false;
    },
    toggleUserDropdown() {
      this.navbarOpen = false;
      this.userDropdownOpen = !this.userDropdownOpen;
    },
    search(val) {
      this.$store.commit('shared/setSearchValue', val);
    },
    async openPWAInstallPopup() {
      if (this.isIOS) {
        this.$store.commit('shared/setIosPwaInstallPopup', {
          open: true,
          dismissed: this.iosPwaInstallPopup.dismissed,
        });
      } else if (!this.isIOS && this.deferredPrompt) {
        // try to open the pwa install prompt of the browser
        this.deferredPrompt.prompt();
      } else {
        this.$notification({ message: 'Het lukt op dit moment niet om Hofweb te installeren, probeer het later opnieuw', type: 'error' });
      }
    },
  },
};
</script>

<style lang="scss">
.navbar {
  width: 100%;

  &--empty-icon{
    color: $hunterGreen;
    font-weight: 100;
    font-size: 16px;
  }

  &--top {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 80px;
    background-color: $floralWhite;
    background-image: url("~assets/images/news-background.png");
    background-position: 20% -40%;
  }

  &--inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1340px;
    height: 100%;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
  }

  &--hamburger {
    position: relative;
    display: none;
    flex-direction: column;
    width: 25px;
    margin: 0 20px 0 0;
    text-align: center;

    .hamburger-item {
      width: 24px;
      height: 2px;
      margin: 3px 0;
      border-radius: 2px;
      transform: rotate(0deg);
      background: $hunterGreen;
      transition: $transition;
    }

    .hamburger-text {
      color: $quickSilver;
      font-weight: 600;
      font-size: 8px;
      text-transform: uppercase;
    }

    &.hamburger-open {

      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 4px;
        z-index: 1;
        border-width: 0 8px 8px 8px;
        border-style: solid;
        border-color: transparent;
        transition: $transition;
      }

      &::before {
        bottom: -13px;
        border-bottom-color: $eggshell;
      }

      &::after {
        bottom: -15px;
        border-bottom-color: $white;
      }

      .hamburger-item {

        &.top {
          transform: rotate(45deg) translate(6px, 6px);
        }

        &.long {
          width: 0;
        }

        &.bottom {
          transform: rotate(-45deg) translate(5px, -5px);
        }
      }
    }
  }

  &--logo {
    position: relative;
    flex: 1;
    height: 45px;
    background-image: url("~assets/images/hofweb-logo.svg");
    background-size: 180px;
    background-repeat: no-repeat;

    @media screen and (min-width: 1170px) {

      &::after {
        content: "Biologisch, direct van de boer!";
        position: absolute;
        top: 6px;
        left: 200px;
        max-width: 144px;
        color: $mayGreen;
        font-size: 16px;
        font-family: $fontBodoni;
        line-height: 20px;
      }
    }
  }

  &--search {
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    height: 48px;
    padding: 0 15px;
    border: 1px solid $grey10;
    border-radius: $radius;
    color: $shadow;
    font-size: 14px;
    line-height: 14px;
    box-sizing: border-box;
    background: $white;

    &-icon {
      color: $hunterGreen;
      font-size: 16px;
    }
  }

  &--icons {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
  }

  &--item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;

    &:last-child {
      padding: 0 0 0 20px;
    }

    &.mobile-search-item {
      display: none;
    }

    &.pwa-install-item {
      display: none;
      padding: 0 !important;
    }
  }

  &--icon {
    color: $hunterGreen;
    font-size: 23px;

    &.arrow {
      display: none;
      margin: 5px 0 0 10px;
      font-size: 20px;

      &.visible {
        display: block;
      }
    }

    &.user-mobile {
      display: none;

      &.fa-times {
        margin: 0 0 0 2px;
        font-size: 25px;
      }
    }

    &.pwa-install {
      color: $azure;
      background: rgba($azure, .15);
      padding: 5px 10px;
      border-radius: 30px;
    }
  }

  &--username {
    display: none;
    margin: 2px 0 0 10px;
    color: $hunterGreen;
    font-weight: 500;
    font-size: 14px;

    &.visible {
      display: block;
    }
  }

  &--cartamount {
    position: absolute;
    top: -12px;
    right: -12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 18px;
    padding: 0 5px;
    border-radius: 18px;
    color: $hunterGreen;
    font-weight: bold;
    font-size: 13px;
    background: $goldCrayola;

    &.empty {
      display: none;
    }
  }

  &--bottom {
    position: fixed;
    top: 80px;
    z-index: 79;
    width: 100%;
    height: 50px;
    background: $hunterGreen;

    &.elevated {
      z-index: 80;
    }
  }

  &--mainmenu,
  &--submenu {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &--link,
  &--link.button {
    position: relative;
    padding: 0 20px;
    color: $white;
    font-weight: 500;
    font-size: 15px;
    background: none;

    &-icon {
      margin: 0 5px 0 10px;
    }
  }

  &--mobile {
    display: none;
  }

  @media screen and (max-width: $breakpoint-md) {

    &--top {
      height: 60px;
      background: $white;
    }

    &--hamburger {
      display: flex;
    }

    &--inner {
      border-bottom: 1px solid $alabaster;
    }

    &--icon {
      font-size: 20px;

      &.arrow,
      &.user {
        display: none;
      }

      &.user-mobile {
        display: block;
      }
    }

    &--username {
      display: none;

      &.visible {
        display: none;
      }
    }

    .user-menu-item {

      .arrow {
        display: none;
      }
    }

    &--logo {
      height: 35px;
      background-size: 140px;

      &::after {
        content: unset;
      }
    }

    &--search {
      display: none;
    }

    &--icons {
      padding: 0 12px 0 0;

      &.has-pwa-install-icon {
        padding: 0;
      }
    }

    &--item {
      padding: 0 12px;

      &:last-child {
        padding: 0 0 0 15px;
      }

      &.mobile-search-item {
        display: flex;
      }

      @media (display-mode: browser) {
        &.pwa-install-item {
          display: block;
        }
      }
    }

    &--bottom {
      position: fixed;
      top: 60px;
      bottom: 0;
      z-index: 60;
      display: none;
      height: auto;
      background: $white;

      &.visible {
        display: block;
      }
    }

    &--mainmenu,
    &--submenu {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      height: auto;
      padding: 15px 0;
    }

    &--link {
      padding: 8px 20px;
      color: $black;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;

      &.more {
        display: none;
      }
    }

    &--submenu {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;

      .button.contact {
        width: calc(100% - 40px);
        margin: 10px 20px 10px 20px;
        padding: 10px;
      }

      .navbar--link {
        padding: 8px 20px;
        color: $quickSilver;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
      }
    }

    &--button.button {
      position: relative;
      z-index: 1;
      margin: 5px 0 0 0;
      padding: 10px;
    }

    &--mobile {
      display: block;
      padding: 0 20px;
    }
  }

  @media screen and (max-width: $breakpoint-xs) {

    &--logo {
      max-width: 90px;
      height: 30px;
      background-position: 50% 36%;
      background-size: contain;
    }
  }
}
</style>
